<template>
    <Layout>
        <div class="w-100">
            <ValidationObserver ref="form">
                <div class="row justify-content-center">
                    <div class="col-11 col-sm-11 col-md-10 login-wrapper py-5">
                        <div class="mx-auto login-w-limit">
                            <div class="logo-wrapper text-center mb-2">

                            </div>
                            <h4 class="text-center by-3">
                                {{ $t('dormitory_application_form') }}
                            </h4>
                        </div>
                    </div>
                </div>

                <div class="row pl-md-5 pl-sm-1">
                    <div class="col-12 mb-2 pl-5">
                        <b-alert
                            :variant="`${formStatus == 'declined' ? 'danger' : formStatus == 'approved' ? 'success' : 'primary'}`"
                            style="max-width: 20rem;" show>
                            <span class="font-weight-bold">{{ formTitle }}</span>
                        </b-alert>
                    </div>
                </div>

                <template v-if="formStatus === 'approved'">
                    <p class="mt-3 px-5 mb-5" v-html="$t('unihall_approved_message')"></p>
                </template>

                <div class="row pl-md-5 pl-sm-1">
                    <div class="col-12 mb-4 pl-5">
                        <span class="font-weight-bold">{{ $t('student_information') }}</span>
                    </div>
                    <div class="col-12 col-md-5 d-flex flex-row student-info">
                        <div class="col-5 d-flex flex-column">
                            <span class="text-right mr-2">{{ $t('student_number') }}:</span>
                            <span class="text-right mr-2">{{ $t('name') }}:</span>
                            <span class="text-right mr-2">{{ $t('surname') }}:</span>
                            <span class="text-right mr-2">{{ $t('class') }}:</span>
                        </div>
                        <div class="col-7 d-flex flex-column">
                            <span>{{ innerForm.student_number }}</span>
                            <span>{{ innerForm.name }}</span>
                            <span>{{ innerForm.surname }}</span>
                            <span>{{ getLocaleText(innerForm, "class_name") }}</span>
                        </div>

                    </div>
                    <div class="col-12 col-md-6 mt-md-0 mt-3 d-flex flex-row student-info">
                        <div class="col-5 d-flex flex-column">
                            <span class="text-right mr-2">{{ $t('national_id') }}:</span>
                            <span class="text-right mr-2">{{ $t('faculty') }}:</span>
                            <span class="text-right mr-2">{{ $t('program') }}:</span>
                            <span class="text-right mr-2">{{ $t('registration_academic_year') }}:</span>
                        </div>
                        <div class="col-7 d-flex flex-column">
                            <span>{{ innerForm.national_id }}</span>
                            <span>{{ getLocaleText(innerForm, "faculty_name") }}</span>
                            <span>{{ getLocaleText(innerForm, "program_name") }}</span>
                            <span>{{ innerForm.registration_academic_year }} {{ getLocaleText(innerForm,
                                "registration_semester_name") }}</span>
                        </div>
                    </div>
                </div>
                <div class="divider my-3"></div>
                <template v-if="generalStatus">
                    <div class="row pl-md-5 px-sm-2">
                        <div class="col-12 mb-3">
                            <span class="font-weight-bold">{{ $t('unihall_quota_select') }}</span>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <ValidationProvider name="quota" rules="required" v-slot="{ errors }">
                                <b-form-group :label="$t('unihall_quota_type')">
                                    <b-form-select v-model="innerForm.unihall_quota_id" :disabled="formDisabled"
                                        :options="quotaItems" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <ValidationProvider name="price" rules="required" v-slot="{ errors }">
                                <b-form-group :label="$t('payment')">
                                    <b-form-select v-model="innerForm.unihall_quota_price_id" :disabled="formDisabled"
                                        :options="priceListItems" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div v-if="formStatus === 'waiting'" class="col-sm-12 col-md-6 col-lg-4">
                            <b-form-group :label="$t('unihall_remaining_quota')">
                                <b-form-input type="text" disabled v-model="remaining_quota" />
                            </b-form-group>
                        </div>
                    </div>
                    <div class="divider my-3"></div>

                    <div class="row pl-md-5 px-sm-2">
                        <div class="col-6">
                            <div class="col-12 mb-3">
                                <span class="font-weight-bold">{{ $t('payment_info') }}</span>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <b-form-group :label="$t('payment_type')">
                                    <ValidationProvider name="credit_card"
                                        :rules="`${!innerForm.payment_type ? 'required' : ''}${innerForm.installment ? '|oneOf:credit_card' : ''}`"
                                        v-slot="{ errors }">
                                        <b-form-checkbox :value="'credit_card'" :unchecked-value="false"
                                            :disabled="formDisabled" v-model="innerForm.payment_type" class="mb-2">
                                            {{ $t('credit_card') }}
                                        </b-form-checkbox>
                                    </ValidationProvider>

                                    <ValidationProvider name="money_order"
                                        :rules="`${!innerForm.payment_type ? 'required' : ''}`" v-slot="{ errors }">
                                        <b-form-checkbox :value="'money_order'" :unchecked-value="false"
                                            :disabled="formDisabled" v-model="innerForm.payment_type">
                                            {{ $t('wire') }}
                                        </b-form-checkbox>
                                        <!-- <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback> -->
                                    </ValidationProvider>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="col-12 mb-3">
                                <span class="font-weight-bold">{{ $t('installment') }}</span>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <b-form-group :label="$t('payment_type1')">
                                    <b-form-checkbox :value="false" :disabled="true" v-model="installmentVal" class="mb-2">
                                        {{ $t('cash_in_advance') }}
                                    </b-form-checkbox>
                                    <b-form-checkbox :value="true" :disabled="true" v-model="installmentVal">
                                        {{ $t('installment_payment') }}
                                    </b-form-checkbox>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="divider my-3" v-if="formStatus === 'waiting_payment'"></div>
                    <ValidationObserver ref="payment">
                        <div class="row pl-md-5 px-sm-2"
                            v-if="formStatus === 'waiting_payment' && innerForm.payment_type === 'credit_card'">
                            <div class="col-12 mb-3">
                                <span class="font-weight-bold">{{ $t('credit_card_info') }}</span>
                            </div>
                            <div class="col-sm-12 col-md-5 col-lg-3">
                                <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('credit_card_name_surname')">
                                        <b-form-input v-model="payData.name" :state="errors[0] ? false : null"
                                            type="text" />
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-sm-12 col-md-5 col-lg-3">
                                <ValidationProvider name="card_number" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('credit_card_number')">
                                        <b-form-input type="text" :state="errors[0] ? false : null"
                                            v-model="payData.card_number" />
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-sm-6 col-md-3 col-lg-2">
                                <ValidationProvider name="expiry_month" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('month')">
                                        <b-form-select v-model="payData.expiry_month" :state="errors[0] ? false : null"
                                            :options="['01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12]"></b-form-select>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-sm-6 col-md-3 col-lg-2">
                                <ValidationProvider name="expiry_year" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('year')">
                                        <b-form-select v-model="payData.expiry_year" :options="years"
                                            :state="errors[0] ? false : null"></b-form-select>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-sm-6 col-md-5 col-lg-2">
                                <ValidationProvider name="cvv" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="'CVV'">
                                        <b-form-input type="text" v-model="payData.cvv" :state="errors[0] ? false : null" />
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                        </div>
                    </ValidationObserver>
                    <div class="divider my-3"></div>
                    <div class="row pl-md-5 px-sm-2" v-if="innerForm.payment_type === 'money_order'">
                        <b-card style="width: 100%;" no-body class="mb-2">
                            <b-card-body>
                                <b-card-text>
                                    <div class="mb-2 font-size-1">{{ $t('wire_info') }}.</div>

                                    <div>
                                        <span class="mr-2">{{ $t('bank') }}:</span>
                                        <span>DenizBank</span>
                                    </div>
                                    <div>
                                        <span class="mr-2">{{ $t('branch_name') }}:</span>
                                        <span>{{ $t('bahcesehir_university') }}</span>
                                    </div>
                                    <div class="my-2">
                                        <span class="mr-2">{{ $t('title1') }}:</span>
                                        <span>{{ $t('bahcesehir_accommodation_and_tourism') }}</span>

                                    </div>
                                    <div>
                                        <span>{{ $t('currency') }}:</span>
                                        <span>TRY</span>
                                    </div>
                                    <div class="mb-2">
                                        <span>IBAN</span>
                                        <span>TR080013400001818718500001</span>
                                    </div>
                                    <div>
                                        <span>{{ $t('currency') }}:</span>
                                        <span>USD</span>
                                    </div>
                                    <div>
                                        <span>IBAN</span>
                                        <span>TR780013400001818718500002</span>
                                    </div>
                                </b-card-text>
                            </b-card-body>
                            <div class="divider my-1"></div>
                            <b-card-body class="py-1">
                                <b-card-text>
                                    <div class="mb-2">{{ $t('unihall_payment_note') }}</div>
                                    <div>
                                        {{ $t('unihall_payment_note1') }}
                                    </div>
                                </b-card-text>
                            </b-card-body>
                        </b-card>
                    </div>
                    <div class="d-flex justify-content-center align-items-center" v-if="generalStatus">
                        <b-button v-if="['waiting'].includes(formStatus)" variant="primary" class="mr-2" @click="save"
                            :disabled="loading">
                            {{ $t('save') }}
                        </b-button>
                        <b-button v-else-if="!['approved', 'declined'].includes(formStatus)" variant="primary" @click="paymentFunc"
                            :disabled="loading">
                            {{ $t('pay') }}
                        </b-button>
                        <!-- <div v-show="iframeSrc" id="iframe-content"></div> -->
                    </div>
                </template>
                <template v-if="!generalStatus">
                    <p class="font-size-1 text-center mt-5">{{ $t('undergraduate_check_date_message') }}</p>
                    <p class="mt-2 px-3" v-html="$t('unihall_quota_full')">
                    </p>
                </template>
                <CommonModal ref="paymentModal" no-close-on-backdrop size="xl">
                    <template v-slot:CommonModalTitle>
                        <div>{{ $t('epayment').toUpper() }}</div>
                    </template>
                    <template v-slot:CommonModalContent>
                        <div class="row">
                            <div class="col-12 mb-4" style="white-space: pre-line;">
                                <iframe sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts"
                                    :srcdoc="iframeSrc" frameborder="1" id="payment-frame"></iframe>
                            </div>
                        </div>
                        <b-button variant="primary" type="button" @click="closePayment">
                            {{ $t('close') }}
                        </b-button>
                    </template>
                </CommonModal>
                <CommonModal ref="kvkkModal" size="lg">
                    <template v-slot:CommonModalTitle>
                        <div> {{ $t('kvkk_modal_title').toUpper() }}</div>
                    </template>
                    <template v-slot:CommonModalContent>
                        <div class="row">
                            <div class="col-12 mb-4" style="white-space: pre-line;">
                                <p v-html="$t('kvkk_content_text')"></p>
                            </div>
                        </div>
                        <b-button variant="primary" type="button" @click="approve('kvkk')">{{ $t('read_and_accept')
                        }}</b-button>
                    </template>
                </CommonModal>
            </ValidationObserver>
            <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" size="invisible"
                sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG" />
        </div>
    </Layout>
</template>
<script>
import { VueRecaptcha } from 'vue-recaptcha'
import { mapGetters } from "vuex"

import UnihallService from '@/services/UnihallService'
import Base from "@/plugins/Base";

export default {
    name: "UnihallApplication",
    components: {
        VueRecaptcha,
        Layout: () => import("../layout/Layout")
    },
    data() {
        return {
            loading: false,
            remaining_quota: null,
            selected_price: null,
            quotas: [],
            priceList: [],
            payData: {},
            iframeSrc: "",
            paymentStatus: null,
            intervalNum: -1,
            generalStatus: false,
            installmentVal: null
        }
    },
    watch: {
        'innerForm.unihall_quota_id': {
            async handler(val) {
                if (!val) return

                const find = this.quotas.find(item => item.id === val)
                if (find) this.remaining_quota = find.quota
                const response = await UnihallService.priceList({ token: this.token, unihall_quota_id: val })
                if (response?.data.success) {
                    this.priceList = response.data.data
                }
            },
            immediate: true
        },
        'innerForm.unihall_quota_price_id': {
            async handler(val) {
                if (!val) return

                const find = this.priceList.find(item => item.id === val)
                if (!find) return

                // if (find.installment > 1) {
                //     this.innerForm.payment_type = "credit_card"
                // }

                this.innerForm.installment = find.installment !== 1
                this.installmentVal = find.installment !== 1

                if (this.innerForm.installment && this.innerForm.payment_type === "money_order") {
                    this.innerForm.payment_type = "credit_card"
                }
            },
            immediate: true
        },
        'innerForm.installment': {
            handler(val) {
                if (val && this.innerForm.payment_type === "money_order") {
                    this.innerForm.payment_type = "credit_card"
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            form: 'unihall/getForm',
            token: 'unihall/getToken',
        }),
        formTitle() {
            if (this.formStatus === "approved") {
                return this.$t('unihall_approvet_payment')
            } else if (this.formStatus === "waiting") {
                return `${this.$t('application')} ${this.$t('status_waiting')}`
            } else if (this.formStatus === "waiting_payment") {
                return ` ${this.$t('waiting_payment')}`
            } else if (this.formStatus === "declined") {
                return ` ${this.$t('your_application_has_been_rejected')}`
            }

            return ""
        },
        moneyOrderStatus() {
            if (!this.innerForm.unihall_quota_price_id) return false

            const find = this.priceList.find(item => item.id === this.innerForm.unihall_quota_price_id)
            if (!find) return false

            return find.installment === 1
        },
        quotaItems() {
            return this.quotas.map(item => ({ value: item.id, text: `${item.name} ` }))
        },
        priceListItems() {
            return this.priceList.map(item => ({ value: item.id, text: `${this.$t('price')}: ${item.price} ${item.currency} / ${item.installment === 1 ? `${this.$t('cash_in_advance')}` : `${this.$t('installment')}: ${item.installment}`}` }))
        },
        formStatus() {
            return this.innerForm.status
        },
        formDisabled() {
            return ["waiting_payment", "approved", "declined"].includes(this.innerForm.status)
        },
        years() {
            let year = new Date().getFullYear()
            let index = 0
            const nums = []

            while (index < 11) {
                nums.push(year + index)
                index++
            }

            return nums
        },
        innerForm: {
            get() {
                return this.form
            },
            set(val) {
                this.$store.commit('unihall/setForm', val)
            }
        }
    },
    methods: {
        async save() {
            const valid = await this.$refs['form'].validate()

            if (this.innerForm.payment_type === "money_order" && this.innerForm.installment) {
                this.$toast.error(this.$t('statement5_error'));
                return
            }

            if (valid) {
                const data = {
                    token: this.token,
                    unihall_quota_id: this.innerForm.unihall_quota_id,
                    unihall_quota_price_id: this.innerForm.unihall_quota_price_id,
                    payment_type: this.innerForm.payment_type,
                }
                const response = await UnihallService.makeApplication(data)

                if (response.data.success) {
                    this.getForm()

                    if (this.innerForm.payment_type === "money_order") {
                        this.$swal
                            .fire({
                                text: this.$t('unihall_make_order_info_text'),
                                showCancelButton: false,
                                confirmButtonText: this.$t('ok'),

                            })
                            .then(() => { })
                    }
                }
            }
        },
        closePayment() {
            this.$refs[`paymentModal`].$refs.commonModal.hide()
            this.iframeSrc = ""
        },
        async paymentFunc() {
            const valid = await this.$refs['payment'].validate()
            if (!valid) return

            const data = {
                token: this.token,
                card_number: this.payData.card_number.replace(/\s/g, ''),
                expiry_month: this.payData.expiry_month,
                expiry_year: this.payData.expiry_year,
                cvv: this.payData.cvv,
            }
            const response = await UnihallService.makePayment(data).catch(e => this.$toast.error(this.$t('api.' + e.data.message)))

            if (response.status === 200) {
                this.iframeSrc = response.data
                window.open().document.write(this.iframeSrc);
                this.startInterval()
                // const paymentFrame = document.querySelector('#payment-frame')
                // paymentFrame.setAttribute('sandbox', 'allow-same-origin allow-top-navigation allow-forms allow-scripts');
                // this.$refs[`paymentModal`].$refs.commonModal.show()
                return
            }
            this.$toast.error(this.$t('something_went_wrong'));
        },
        async getQuotas() {
            const response = await UnihallService.quotas({ token: this.token })
            if (response.data.success) {
                this.quotas = response.data.data
            }
        },
        async onCaptchaVerified(recaptchaToken) {
            this.form.g_recaptcha_token = recaptchaToken;

            const response = await UnihallService.login(this.form)
                .catch((e) => {
                    this.showErrors(e);
                }).finally(() => {
                    this.$refs.recaptcha.reset()
                })

            if (response.data.success) {
                this.$toast.success(this.$t('password_send_success'));
                this.$router.push('/transfer/login');
            }
        },
        openModal(str, status = true) {
            if (status) {
                this.$refs[`${str}Modal`].$refs.commonModal.show()
            } else {
                this.$refs[`${str}Modal`].$refs.commonModal.hide()
            }

        },
        approve(str) {
            this.$data[str].status = true
            this.openModal(str, false)
        },
        async getForm() {
            const response = await UnihallService.getForm({ token: this.token }).catch((error) => {
                if (error.data.message === "UNAUTHORIZED") {
                    this.$store.dispatch('unihall/logout')
                    return
                }
                this.$toast.error(this.$t('api.' + error.data.message))
            })
            if (response.data.success) {
                this.$store.commit('unihall/setForm', response.data.data)

                if (response.data.data.unihall_quota) {
                    this.quotas = [response.data.data.unihall_quota]
                    this.innerForm.unihall_quota_id = response.data.data.unihall_quota.id
                }
                if (response.data.data.unihall_quota_price) {
                    this.priceList = [response.data.data.unihall_quota_price]
                    this.innerForm.unihall_quota_price_id = response.data.data.unihall_quota_price.id
                }
            }
        },
        startInterval() {
            this.intervalNum = setInterval(() => {
                if (!this.paymentStatus) {
                    this.getPaymentStatus()
                } else {
                    Base.LocalStorage.remove('payment_status');
                    clearInterval(this.intervalNum);
                    location.reload();
                }
            }, 2000);
        },
        getPaymentStatus() {
            this.paymentStatus = Base.LocalStorage.get('payment_status')
        },
        async applicationStatus() {
            try {
                await UnihallService.checkDate()
                this.generalStatus = true
            } catch (e) {
                this.generalStatus = false
            }
        }
    },
    mounted() {
        if (!this.token) {
            this.$store.dispatch('unihall/logout')
            return
        }
        
        this.getQuotas()
        this.getForm()
        this.applicationStatus()
    }
}
</script>
<style lang="scss">
#payment-frame {
    width: 100%;
    height: 28rem;
}
</style>